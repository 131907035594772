.halfButtonContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  row-gap: 15px;
  margin-top: 20px;
}

.button {
  background: #fa3051;
  color: #fff;
  padding: 15px;
  font-weight: 600;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.yourNumber {
  text-align: center;
  margin-bottom: 30px;
}

.bigNumber {
  font-size: 50px;
  font-weight: bold;
}
